<template>
  <div>
    <hr id="yellow-rule" />
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1>
            {{ $t("verified_form_title") }}
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="12">
          <v-alert outlined text type="success">
            <h3 class="text-h6 mb-4">
              {{ $t("success_alert.title") }}
            </h3>
            <p class="font-weight-bold">
              {{ $t("success_alert.subtitle") }}
            </p>
            <div>
              {{ $t("success_alert.body") }}
            </div>
          </v-alert>
        </v-col>
      </v-row>
      <v-form ref="form" :disabled="formDisabled" lazy-validation>
        <!-- Basic Information section -->
        <v-row>
          <v-col cols="12">
            <v-combobox
              append-icon=""
              outlined
              v-model="salaryEntry.company"
              :label="$t('company_name')"
              item-text="name"
              item-value="name"
              :items="companies"
              :filter="companySearch"
              :rules="[isRequired]"
              :return-object="false"
              :placeholder="$t('company_name_placeholder')"
              @keyup="populateCompanyDropdown"
              required
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              outlined
              v-model="salaryEntry.city_id"
              :label="$t('city')"
              item-text="name"
              item-value="id"
              :items="cities"
              :rules="[isRequired]"
              :return-object="false"
              clearable
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="salaryEntry.role_title"
              :label="$t('role_title')"
              :hint="$t('role_title_example')"
              :rules="[isRequired]"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              outlined
              v-model="salaryEntry.job_role"
              @change="updateFocusList"
              :label="$t('job_role')"
              :items="jobRoles"
              item-value="name"
              item-text="name"
              :rules="[isRequired]"
              :return-object="false"
              required
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              outlined
              v-model="focusInput"
              @change="updateFocusValue()"
              :label="$t('focus')"
              :items="roleFocusList"
              :rules="[isRequired]"
              item-text="name"
              item-value="name"
              required
            ></v-select>
            <v-text-field
              outlined
              v-if="focusInput == `Other` || focusInput == `その他`"
              v-model="otherFocusInput"
              :change="updateFocusValue()"
              :label="$t('other_focus')"
              :rules="[isRequired]"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              outlined
              v-model="salaryEntry.grade"
              :label="$t('grade')"
              :hint="$t('grade_example')"
              :rules="[isRequired]"
              persistent-hint
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              outlined
              v-model="salaryEntry.years_of_experience"
              :label="$t('years_of_experience')"
              :rules="[isRequired]"
              :maxLength="2"
              type="tel"
              required
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              v-model="salaryEntry.years_at_company"
              :label="$t('years_at_company')"
              :rules="[
                v => {
                  if (!v) return true;
                  if (
                    !salaryEntry.years_of_experience &&
                    salaryEntry.years_at_company > 0
                  )
                    return $t('enter_yoe_first');
                  return isLessThan(v, salaryEntry.years_of_experience);
                }
              ]"
              :maxLength="2"
              type="tel"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              outlined
              v-model="salaryEntry.age"
              :label="$t('age')"
              :rules="[v => !v || isMoreThan(v, '18')]"
              :maxLength="2"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Compensation information section -->
        <v-row>
          <v-col cols="12">
            <h2>{{ $t("compensation_section") }}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col col="12">
            <h3>{{ $t("total_compensation") }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              id="total-comp-input"
              v-model="annualCompInputValue"
              :label="$t('total_compensation_label')"
              placeholder="8,000,000"
              :rules="[isRequired, v => !v || isMoreThan(v, '1,000,000')]"
              :hint="$t('total_compensation_message')"
              :error-messages="totalCompError"
              :maxLength="11"
              prefix="¥"
              type="tel"
              required
              validate-on-blur
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3>{{ $t("compensation_breakdown") }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="annualSalaryInputValue"
              :label="$t('annual_base_salary')"
              :rules="[isRequired, v => !v || isMoreThan(v, '1,000,000')]"
              :maxLength="11"
              prefix="¥"
              type="tel"
              validate-on-blur
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="annualBonusInputValue"
              :label="$t('annual_bonus')"
              :hint="$t('bonus_message')"
              :rules="[isRequired]"
              :maxLength="11"
              prefix="¥"
              type="tel"
              persistent-hint
              required
              validate-on-blur
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              class="stock-grant-input"
              outlined
              v-model="annualStockInputValue"
              :label="$t('annual_stock')"
              :hint="$t('stock_message')"
              :rules="[isRequired]"
              :maxLength="11"
              prefix="¥"
              type="tel"
              persistent-hint
              required
              validate-on-blur
            >
              <template v-slot:message="{ message, key }">
                <div v-html="message" :key="key"></div>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Signing bonus (optional) -->
        <v-row>
          <v-col cols="12">
            <v-expansion-panels flat tile>
              <v-expansion-panel>
                <v-expansion-panel-header
                  color="gray_lighter"
                  class="py-1 px-5"
                >
                  <template v-slot:actions class="expand-icon">
                    <v-icon>$expand</v-icon>
                  </template>
                  <span class="expand-header ml-2"
                    >{{ $t("add_signing_bonus") }} ({{ $t("optional") }})</span
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  class="pt-4 text-subtitle-2 gray_darker--text"
                  >{{
                    $t("signing_bonus_disclaimer")
                  }}</v-expansion-panel-content
                >
                <v-expansion-panel-content v-for="i in [1, 2, 3]" :key="i">
                  <v-text-field
                    v-model="signingBonus[i]"
                    outlined
                    prefix="¥"
                    :rules="[v => !v || isLessThan(v, '100,000,000')]"
                    validate-on-blur
                    :maxLength="11"
                    :label="$t('year', { year: i })"
                    type="tel"
                    hide-details="auto"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <!-- Misc (nationality, gender, etc) -->
        <v-row>
          <v-col cols="12">
            <h2>{{ $t("other_details") }}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3 class="text-subtitle-2">
              {{ $t("technology_tags_input.title") }} ({{ $t("optional") }})
            </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-combobox
              :menu-props="menuProps"
              v-model="salaryEntry.technology_list"
              :items="technologyTags"
              :search-input.sync="searchTechnologyTags"
              hide-selected
              :hint="$t('technology_tags_input.hint')"
              :placeholder="$t('technology_tags_input.placeholder')"
              :label="$t('technology_tags_input.label')"
              outlined
              solo
              flat
              item-text="name"
              item-value="name"
              :return-object="false"
              multiple
              persistent-hint
              small-chips
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <i18n
                        path="technology_tags_input.not_found_placeholder"
                        tag="div"
                      >
                        <template v-slot:search>
                          <span
                            ><strong>{{ searchTechnologyTags }}</strong></span
                          >
                        </template>
                        <template v-slot:enter>
                          <kbd>{{ $t("technology_tags_input.enter") }}</kbd>
                        </template>
                      </i18n>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3 class="text-subtitle-2">
              {{ $t("nationality") }} ({{ $t("optional") }})
            </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn-toggle v-model="salaryEntry.nationality">
              <v-btn value="japanese">
                {{ $t("japanese_nationality") }}
              </v-btn>
              <v-btn value="foreigner">
                {{ $t("non_japanese_nationality") }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3 class="text-subtitle-2">
              {{ $t("gender") }} ({{ $t("optional") }})
            </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-btn-toggle v-model="salaryEntry.gender">
              <v-btn value="male">
                {{ $t("male") }}
              </v-btn>
              <v-btn value="female">
                {{ $t("female") }}
              </v-btn>
              <v-btn value="other">
                {{ $t("other") }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <v-col col="12">
            <h3 class="text-subtitle-2">{{ $t("education_level") }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" md="4">
            <v-select
              outlined
              clearable
              hide-details
              :items="educationLevels"
              item-text="0"
              item-value="1"
              :placeholder="$t('education_level_placeholder')"
              v-model="salaryEntry.education_level"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3 class="text-subtitle-2">
              {{ $t("additional_info") }} ({{ $t("optional") }})
            </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col small="12" md="5" lg="5">
            <v-textarea
              outlined
              hide-details
              name="additional_info"
              :placeholder="$t('feedback_note')"
              v-model="salaryEntry.additional_info"
            ></v-textarea>
          </v-col>
        </v-row>
        <!-- Email -->
        <v-row>
          <v-col col="12">
            <h3 class="text-subtitle-2">
              {{ $t("email") }} ({{ $t("optional") }})
            </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="salaryEntry.email"
              placeholder="example@email.com"
              :rules="[email => !email || validateEmail(email)]"
              validate-on-blur
              persistent-hint
              outlined
              @input="salaryEntry.email = salaryEntry.email || null"
            />
            <v-alert
              class="text-caption p-4"
              color="rgba(128, 128, 128, 0.05)"
              >{{ $t("email_disclaimer") }}</v-alert
            >
          </v-col>
        </v-row>
        <v-row justify="center" class="my-2">
          <v-btn
            color="secondary"
            :loading="formDisabled"
            x-large
            dark
            rounded
            @click="createSalary"
          >
            {{ $t("submit") }}
          </v-btn>
        </v-row>
        <!-- Error when submit -->
        <v-row id="form-error">
          <v-col>
            <form-error v-show="errors.length > 0" :errors="errors" />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<i18n>
{
  "en": {
    "verified_form_title": "Anonymous Verified Salary Submission",
    "what_is_this": "What is this?",
    "upload_documents": "Upload Verification Documents",
    "upload_documents_hint": "Offer letters (old or unaccepted ones are also fine), income tax declaration, promotion or salary change documents or anything that can be used as proof of compensation.",
    "company_name": "Company Name",
    "company_name_placeholder": "eg. Rakuten, Google, Mercari",
    "city": "City",
    "grade": "Grade/Level",
    "grade_example": "example: EX2, G4, Software Engineer I",
    "role_title_example": "e.g. Senior Software Engineer, Application Engineer",
    "role_title": "Official Job Title",
    "job_role": "Job Category",
    "focus": "Focus",
    "other_focus": "Other focus",
    "years_of_experience": "Years of Experience",
    "years_of_experience_message": "Number of years of relevant work experience",
    "years_at_company": "Years at Company (Optional)",
    "compensation_section": "Compensation",
    "total_compensation": "Total Annual Compensation",
    "total_compensation_message": "Your expected TOTAL annual income. Don't forget to include expected bonus and stock grants.",
    "total_compensation_label": "Annual Base Salary + Annual Bonus + Annual Equity Grants",
    "age": "Age (Optional)",
    "education_level_placeholder": "Select highest education",
    "education_level": "Education Level (Optional)",
    "optional": "Optional",
    "compensation_breakdown": "Compensation Breakdown",
    "annual_base_salary": "Annual Base Salary",
    "annual_bonus": "Annual Expected Bonus",
    "annual_stock": "Annual Equity Grants",
    "stock_message": "Please use the grant price if possible. If you don't recieve any, please write 0. Please make sure you input the yearly average (ie, if you recieve 10M for 5 years, please input 2M.",
    "bonus_message": "If you don't recieve any, please write 0",
    "add_signing_bonus": "Add Signing Bonus",
    "signing_bonus_disclaimer": "Signing bonus will not be included in the total annual compensation.",
    "year": "Year {year}",
    "other_details": "Other Details",
    "technology_tags_input": {
      "title": "Technologies and Tools",
      "label": "Add technologies and tools",
      "hint": "Add the primary technologies you use in your day to day work. Max 10.",
      "placeholder": "Golang, Figma, React.js",
      "not_found_placeholder": "Press {enter} to add {search} tag",
      "enter": "enter"
    },
    "nationality": "Nationality",
    "japanese_nationality": "Japanese",
    "non_japanese_nationality": "Non-Japanese",
    "gender": "Gender",
    "male": "Male",
    "female": "Female",
    "other": "Other",
    "submit": "submit",
    "field_required": "This field is required",
    "less_than": "Must be {n} or less",
    "more_than": "Must be {n} or more",
    "enter_yoe_first": "Enter your years of experience first",
    "add_normal_compensation": "Or add an unverified compensation",
    "show_label": "Show a verified label on this entry on the site.",
    "previous_submission": "I am verifying a previous submission.",
    "additional_info": "Additional Info",
    "feedback_note": "Feedback for us or anything you want. Will not be shared.",
    "success_alert": {
      "title": "Got it! Thanks for contributing to salary transparency in a big way!",
      "subtitle": "Optional Step #2: Fill out the below form to have your entry displayed in the site",
      "body": "We've received your documentation, you don't need to do anything else but if you would like your submission to appear on the site please fill out the additional information in the form below."
    },
    "email": "Email",
    "email_disclaimer": "We will contact this email if we have questions about this entry. If your entry seems like an outlier, we would really appreciate it if you submitted your email 🙇",
    "invalid_email": "Invalid email address",
    "not_entered": "not entered",
    "comp_breakdown_sum_error": "must be equal to sum of annual base salary, annual expected bonus, and annual equity grants"
  },
  "ja": {
    "verified_form_title": "認証情報の提出",
    "what_is_this": "このページについて",
    "upload_documents": "認証書類をアップロード",
    "upload_documents_hint": "オファーレター（古いものや、過去に断ったものでも大丈夫です）、源泉徴収票、給与変更・昇給の書類など、年収を証明するものなら何でも構いません。",
    "company_name": "企業名",
    "company_name_placeholder": "例： 楽天, Google, メルカリ",
    "city": "所在地",
    "grade": "グレード・レベル",
    "grade_example": "例：EX2, G4, Software Engineer I",
    "role_example": "例： Software Engineer, Engineering Manager",
    "role_title_example": "例：アプリケーションエンジニア",
    "role_title": "企業内での職種名",
    "job_role": "職種",
    "focus": "フォーカス",
    "other_focus": "その他",
    "years_of_experience": "経験年数",
    "years_at_company": "勤続年数 （任意）",
    "years_of_experience_message": "経験年数",
    "age": "年齢（任意）",
    "education_level_placeholder": "選択してください",
    "education_level": "最終学歴（任意）",
    "compensation_section": "年間報酬",
    "total_compensation": "年収",
    "total_compensation_message": "「会社から支払われるお金全部（年間の総支給額）」のことですので、ボーナス（賞与）や ストップ・オプション（株式報酬）も含めましょう。",
    "total_compensation_label": "基本給(年) + 賞与(年) + 株式報酬(年)",
    "optional": "任意",
    "compensation_breakdown": "年収詳細",
    "annual_base_salary": "基本給(年)",
    "annual_bonus": "賞与(年)",
    "annual_stock": "株式報酬(年)",
    "stock_message": "付与された全額に対して、全額換金が完了する期間で割った金額を入力して下さい。<br>例）想定価格1,000万円、全額換金完了に5年を要する場合、「2,000,000」（200万円）と入力",
    "bonus_message": "該当しない場合は「0」を入力してください。",
    "add_signing_bonus": "サインアップボーナスを登録",
    "signing_bonus_disclaimer": "入力された金額は年間報酬には含まれません。",
    "year": "{year}年目",
    "other_details": "その他",
    "technology_tags_input": {
      "title": "技術・ツール",
      "label": "技術・ツールを追加",
      "hint": "日常的に業務で使用している主な技術やツールを入力してください。（10個まで）",
      "placeholder": "Golang, Figma, React.js",
      "not_found_placeholder": "{enter}を押して{search}を追加する",
      "enter": "Enter"
    },
    "nationality": "国籍",
    "japanese_nationality": "日本国籍",
    "non_japanese_nationality": "外国籍",
    "gender": "性別",
    "male": "男性",
    "female": "女性",
    "other": "その他",
    "submit": "追加する",
    "field_required": "この項目は入力必須です。",
    "less_than": "{n}以下の値にしてください。",
    "more_than": "{n}以上の値にしてください。",
    "enter_yoe_first": "経験年数を入力してください。",
    "add_normal_compensation": "または、認証せずに年収を追加",
    "show_label": "認証済みマークを表示する。",
    "previous_submission": "提出済み年収を認証する。",
    "additional_info": "補足",
    "feedback_note": "OpenSalaryへのフィードバックなど。公開されません。",
    "success_alert": {
      "title": " 給与の透明性に貢献して頂きありがとうございます！",
      "subtitle": "任意のステップ #2: あなたの給与情報を本サイトに掲載するため、以下の情報を記入してください。",
      "body": "書類を受領しました。あなたの給与情報を本サイトに掲載しても良い場合は、以下の情報を記入してください。"
    },
    "email": "メールアドレス",
    "email_disclaimer": "営業メールは致しません。特録した給与が他のデータから乖離していた場合や給与情報に関する確認事項がある場合はこのメールアドレスにご連絡させて頂くことがございます。あらかじめご了承ください。",
    "invalid_email": "不正なメールアドレスです。",
    "not_entered": "が入力されていません。",
    "comp_breakdown_sum_error": "の合計が基本給、賞与、株式報酬の合計と一致しません。"
  }
}
</i18n>

<script>
import api from "services/api";
import _ from "lodash";
import validateEmail from "../utils/validateEmail";
import delimitNumber from "../utils/delimitNumber";
import FormError from "components/FormError";

export default {
  components: { FormError },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used

      title: this.$t("verified_form_title"),
      // all titles will be injected into this template
      titleTemplate: "%s | OpenSalary",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "An open database of developer salaries in Japan"
        },
        {
          vmid: "og-title",
          property: "og:title",
          content: "Verified Compensation Submission | OpenSalary"
        },
        {
          vmid: "og-description",
          property: "og:description",
          content:
            "Help other developers like you and submit your compensation!"
        }
      ]
    };
  },
  data: () => ({
    formDisabled: false,
    companies: [],
    cities: [],
    jobRoles: [],
    roleFocusList: [],
    educationLevels: [],
    annualCompInputValue: "",
    annualSalaryInputValue: "",
    annualBonusInputValue: "",
    annualStockInputValue: "",
    signingBonus: {
      1: "",
      2: "",
      3: ""
    },
    totalCompError: "",
    focusInput: "",
    otherFocusInput: "",
    technologyTags: [],
    searchTechnologyTags: null,
    salaryEntry: {
      verified_label_permission: true,
      verifying_previous_entry: false,
      company: "",
      city_id: "",
      role_title: "",
      role_focus: "",
      job_role: "",
      grade: "",
      annual_compensation: "",
      annual_salary: "",
      annual_bonus: "",
      annual_stock: "",
      signing_bonus_1: null,
      signing_bonus_2: null,
      signing_bonus_3: null,
      years_of_experience: "",
      years_at_company: "",
      technology_list: [],
      nationality: null,
      additional_info: null,
      gender: null,
      education_level: null,
      verification_documents_id: null,
      email: null
    },
    errors: []
  }),
  created() {
    this.getCompanies();
    this.getCities();
    this.getJobRoles();
    this.getEducationLevels();
    this.getTags();

    const verificationDocumentsId = this.$route.params
      .verification_documents_id;
    if (verificationDocumentsId) {
      this.salaryEntry.verification_documents_id = verificationDocumentsId;
    } else {
      this.$router.push({ name: "submitCompensationSelection" });
    }
  },
  computed: {
    menuProps() {
      return !this.searchTechnologyTags ? { value: false } : {};
    }
  },
  watch: {
    annualCompInputValue: function(newValue) {
      if (!newValue) return;

      const result = delimitNumber(newValue);
      this.$nextTick(() => {
        this.annualCompInputValue = result;
        this.salaryEntry.annual_compensation = parseInt(
          result.replace(/,/g, "")
        );
      });
    },
    annualSalaryInputValue: function(newValue) {
      if (!newValue) return;

      const result = delimitNumber(newValue);
      this.$nextTick(() => {
        this.annualSalaryInputValue = result;
        this.salaryEntry.annual_salary = parseInt(result.replace(/,/g, ""));
      });
    },
    annualBonusInputValue: function(newValue) {
      if (!newValue) return;

      const result = delimitNumber(newValue);
      this.$nextTick(() => {
        this.annualBonusInputValue = result;
        this.salaryEntry.annual_bonus = parseInt(result.replace(/,/g, ""));
      });
    },
    annualStockInputValue: function(newValue) {
      if (!newValue) return;

      const result = delimitNumber(newValue);
      this.$nextTick(() => {
        this.annualStockInputValue = result;
        this.salaryEntry.annual_stock = parseInt(result.replace(/,/g, ""));
      });
    },
    "signingBonus.1": function(newValue) {
      if (newValue === null) return;

      this.updateSigningBonus({ num: 1, newValue });
    },
    "signingBonus.2": function(newValue) {
      if (newValue === null) return;

      this.updateSigningBonus({ num: 2, newValue });
    },
    "signingBonus.3": function(newValue) {
      if (newValue === null) return;

      this.updateSigningBonus({ num: 3, newValue });
    },
    "salaryEntry.technology_list": function(val) {
      if (val.length > 10) {
        this.$nextTick(() => this.salaryEntry.technology_list.pop());
      }
    }
  },
  methods: {
    getCompanies(page = 1) {
      const page_query = { page: page };
      const query = {
        ...page_query
      };
      api.getCompanies(query).then(resp => {
        this.companies = resp.data.items;
        this.pagination = resp.data.meta;
      });
    },
    next(page) {
      this.getCompanies(page);
    },
    updateFocusList(e) {
      const roleItem = _.find(this.jobRoles, function(role) {
        return role.name === e;
      });
      const originalList = roleItem.focus_options;
      this.roleFocusList = [...originalList, { name: this.$t("other") }];
    },
    updateFocusValue() {
      if (this.focusInput == "Other" || this.focusInput == "その他") {
        this.salaryEntry.role_focus = this.otherFocusInput;
      } else {
        this.salaryEntry.role_focus = this.focusInput;
      }
    },
    companySearch(company, typedText) {
      // TODO: improve the search for all Japanese characters
      // https://github.com/drewpterry/project-alpha/issues/732

      const hasValue = val => (val != null ? val : "");

      const nameEn = hasValue(company.name_en)
        .toString()
        .toLowerCase();
      const nameJa = hasValue(company.name_ja).toString();
      let searchTerm = hasValue(typedText);

      // if search term are English letters
      if (/^[a-zA-Z]/.test(searchTerm)) {
        searchTerm = searchTerm.toLowerCase();
      } else {
        searchTerm = searchTerm.toString();
      }

      const matchNameEn = nameEn.indexOf(searchTerm) > -1;
      const matchNameJa = nameJa.indexOf(searchTerm) > -1;

      return matchNameEn || matchNameJa;
    },
    populateCompanyDropdown() {
      this.companyDropdown = this.companyList;
    },
    getCities() {
      api.getCities().then(resp => {
        this.cities = resp.data.items;
      });
    },
    getEducationLevels() {
      api.getEducationLevels().then(resp => {
        this.educationLevels = resp.data.items;
      });
    },
    getTags() {
      api.getTags().then(resp => {
        this.technologyTags = resp.data.items;
      });
    },
    getJobRoles() {
      api.getJobRoles().then(resp => {
        this.jobRoles = resp.data.items;

        const roleItem = _.find(this.jobRoles, function(role) {
          return role.name === "Software Engineer";
        });
        const originalList = roleItem.focus_options;
        this.roleFocusList = [...originalList, { name: this.$t("other") }];
      });
    },
    createSalary() {
      if (this.$refs.form.validate() && this.validateCompBreakdownSum()) {
        this.errors = []; // reset error message
        this.totalCompError = ""; // reset error message
        this.formDisabled = true;
        const formData = new FormData();

        formData.append("entry_type", "verified");
        Object.entries(this.salaryEntry).forEach(([key, value]) => {
          const notNullNorUndefined =
            this.salaryEntry[key] !== null &&
            this.salaryEntry[key] !== undefined;

          if (notNullNorUndefined) {
            if (Array.isArray(value)) {
              value.forEach(v => {
                formData.append(key + "[]", v);
              });
            } else {
              formData.append(key, this.salaryEntry[key]);
            }
          }
        });
        api
          .createSalary(formData)
          .then(() => {
            this.$refs.form.reset();
            this.formDisabled = false;
            this.$router.replace(
              this.$i18nRoute({
                name: "thankYouForSubmitting",
                query: { submit_type: "file" }
              })
            );
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        const errors = [];

        [...this.$refs.form.inputs].forEach(input => {
          const hasInputError = input.errorBucket && input.errorBucket.length;

          if (!hasInputError) return;

          const error = {
            message: [...input.errorBucket][0],
            label: input.label
          };

          // Modify labels to make it sound more natural
          if (error.label == null) {
            // Email field currently is the only text field without label
            // so we need to give the label manually
            error.label = this.$t("email");
          } else if (error.label === this.$t("other_focus")) {
            // Other focus field is nested in the focus so rather than showing the lable which is just 'Other'
            // 'Focus > Other' is more clear for the user
            error.label = `${this.$t("focus")} > ${this.$t("other_focus")}`;
          } else if (
            new RegExp(this.$t("year", { year: "\\d" })).test(error.label)
          ) {
            // When the error comes from the signing bonus field
            // 'Signing Bonus > x Years' is more clear for the user
            error.label = `${this.$t("add_signing_bonus")} > ${error.label}`;
          }

          // Modify message to make it sound more natural
          if (error.message == this.$t("field_required")) {
            error.message = this.$t("not_entered");
          }

          errors.push(error);
        });

        // Validate the sums of the compensations
        if (!this.validateCompBreakdownSum()) {
          errors.push({
            message: this.$t("comp_breakdown_sum_error"),
            label: this.$t("total_compensation")
          });
        }

        // Update the data value and show the error message in the component
        this.errors = errors;

        this.$vuetify.goTo("#form-error", {
          duration: 500,
          easing: "easeInOutQuad"
        });
      }
    },
    isRequired(v) {
      return (!!v && !!v.toString().trim()) || this.$t("field_required");
    },
    isLessThan(v, other) {
      const value = parseInt(v.replace(/,/g, ""));
      const otherValue = parseInt(other.replace(/,/g, ""));
      return (
        parseInt(value) <= parseInt(otherValue) ||
        this.$t("less_than", { n: other })
      );
    },
    isMoreThan(v, other) {
      const value = parseInt(v.replace(/,/g, ""));
      const otherValue = parseInt(other.replace(/,/g, ""));
      return (
        parseInt(value) >= parseInt(otherValue) ||
        this.$t("more_than", { n: other })
      );
    },
    validateCompBreakdownSum() {
      const compBreakdown = [
        this.salaryEntry.annual_salary,
        this.salaryEntry.annual_bonus,
        this.salaryEntry.annual_stock
      ];
      const breakdownSum = compBreakdown.reduce((a, b) => a + b, 0);

      if (this.salaryEntry.annual_compensation != breakdownSum) {
        this.totalCompError = `${this.$t("annual_base_salary")} + ${this.$t(
          "annual_bonus"
        )} +
          ${this.$t("annual_bonus")} != ${this.$t("total_compensation")}`;
        return false;
      }
      return true;
    },
    validateEmail(email) {
      // needs to return error message if email is invalid
      return validateEmail(email) || this.$t("invalid_email");
    },
    updateSigningBonus({ num, newValue }) {
      const result = delimitNumber(newValue);
      this.$nextTick(() => {
        this.signingBonus[num] = result;
        this.salaryEntry[`signing_bonus_${num}`] =
          parseInt(result.replace(/,/g, "")) || null;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#yellow-rule {
  border: none;
  background-color: var(--v-accent-base);
  height: 10px;
}

.stock-grant-input ::v-deep {
  .v-messages__message {
    line-height: 16px;
  }
}

.container {
  max-width: 700px;
}

.unverified-link {
  text-decoration: none;
}

.unverified-link:hover {
  text-decoration: underline;
}

.row {
  margin-top: 0px;
  padding-top: 0px;
}
::v-deep .v-expansion-panels {
  border: solid 2px var(--v-gray_lighter-base);
}
.expand-icon {
  order: 0;
}
.expand-header {
  order: 1;
}
::v-deep .v-expansion-panel-header {
  min-height: 2.25rem;
}
</style>
